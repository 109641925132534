.accordian-row {
    .accordian-column{
        border-bottom: 1px solid #d5d5d5;
        width: 100%;
        padding-bottom: 10px;
    }
}
.back-btn{
    margin-right: 15px;
    color: #6c7fd8!important;
    background-color: #fff !important;
    border: 1px solid #6c7fd8 !important;
    font-size: 14px !important;
    width: 120px;
    border-radius: 4px!important;
    padding: 6px 10px;
}
.cancel-btn{
    margin-right: 15px;
    color: #fff!important;
    background-color:#B7B7B7B7  !important;
    border: 1px solid #B7B7B7B7 !important;
    font-size: 14px !important;
    width: 120px;
    border-radius: 4px!important;
}
.car-page{
    .success-modal{
        .modal-content{
            width: auto;
            min-width: 500px;

        }
    }
   
}
.sub-heading{
    font-weight: bold;
}
.sub-text{
    font-size: 14px;
    color: #5d5d5d;
}
.close-icon-wrap{
    text-align: right;
}

