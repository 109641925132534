.proposal-wrap.rider-selection-wrap .forms-wrap form .row {
  padding-left: 0;
}

.rider-selection-wrap {
  * {
    color: #4a4a4a;
  }

  p {
    font-size: 13px;
  }

  .sub-title .sub-title_text {
    margin: 0;
    background-color: #efefef;
    border-radius: 100px;
    width: 45%;
    padding: 3px 3px;
    text-align: center;
  }

  .sub-title::after {
    width: 55%;
  }

  .summary-details {
    text-align: left;
    border: 1px solid #d7d7d7;
    border-radius: 12px;
  }

  .leftside-form {
    padding: 20px 15px 0 15px;
  }

  .heading-title {
    border-bottom: 1px solid #d7d7d7;
    padding-left: 10px;
    padding-right: 10px;
  }

  .text-grey {
    color: #8c8f95;
  }

  .content-wrap {
    padding: 10px 10px;
  }

  .amount-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-success {
    color: #28b961;
  }

  .border-box {
    border: 2px dotted #d7d7d7;
    border-radius: 4px;
    padding: 7px 7px;
  }

  .decoration-none {
    text-decoration: none;
  }

  .infoicon {
    margin-right: 5px;
  }

  .total-info {
    background: #f8f9fa;
  }

  .content-bottom-wrap {
    border-radius: 0px 0px 12px 12px;
    padding: 10px 10px;
  }

  .radius-12 {
    border-radius: 12px;
  }

  .fs-13 {
    font-size: 13px;
  }

  .border-wrap-box {
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    padding: 15px 15px 5px 15px;
    position: relative;
  }

  .btn-xs {
    border: 1px solid #ec2d82;
    background: transparent !important;
    color: #ec2d82;
    padding: 4px 0px !important;
    height: 30px !important;
    max-width: 100%;
    width: 70px !important;
  }

  .btn-xs:hover {
    // color: #fff !important;
    color: #86bff9 !important;
  }

  .fw-600 {
    font-weight: 600;
  }

  .single-box {
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 8px;
    padding-top: 8px;
    position: relative;
  }

  .bordered-title {
    background: #f8f9fa;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    font-size: 12px;
    padding: 4px 10px;
    position: absolute;
    top: -13px;
  }

  .border-wrap-box {
    margin-bottom: 15px;
  }

  .border-wrap-box:last-child {
    margin-bottom: 0px;
  }

  .scroll-box {
    // height: 300px;
    overflow: scroll;
    padding: 15px 0;
  }

  .single-box:last-child {
    border-bottom: none;
  }

  .radio-group label {
    padding: 10px 10px;
    width: 100%;
    margin: 0 10px;
    position: relative;
    z-index: 0;
  }

  .radio-label {
    border: 1px solid #999;
    border-radius: 6px;
    padding: 10px 10px;
    padding-left: 10px;
    padding-left: 10px;
    margin: 0 22px;
    position: absolute;
    left: -22px;
    width: 100%;
    padding-left: 43px;
    z-index: -2;
  }

  .input-radio:checked + .radio-label {
    border: 1px solid #6c7fd8;
    border-radius: 6px;
    padding: 10px 10px;
    padding-left: 10px;
    margin: 0 22px;
    position: absolute;
    left: -22px;
    width: 100%;
    padding-left: 43px;
    z-index: -2;
  }

  .radio-group {
    margin-top: 10px;
    display: flex;
  }

  .radio-group label:first-child {
    margin-left: 0;
  }

  .radio-group label:last-child {
    margin-right: 0;
  }

  .waiting-period-field {
    .form-select {
      background-position: right 30px;
    }
  }

  .checkbox_wrap .label-text {
    color: #989a9f;
    font-size: 12px;
  }

  .cust-checkbox-wrap {
    background-color: transparent;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    padding: 7px 7px;
  }

  .fs-10 {
    font-size: 10px;
  }

  .ml-4 {
    margin-left: 0.4rem;
  }

  .checkBox {
    background-color: transparent;
    border: 1px solid #6c7fd8;
    border-radius: 4px;
    padding: 7px 7px;
  }

  @media screen and (max-width: 767px) {
    .radio-group {
      display: block;
    }

    .radio-group label {
      margin: 10px 0px !important;
    }
  }
}