.custom-checkbox input[type=checkbox]
{
  height: 15px;
    width: 15px;
    margin-left: 6px;
}

.custom-checkbox .checkbox-wrap
{
    align-items: center;
}

.inner-form-box
{
    background: #f2f2f2;
    border-radius: 6px;
    padding: 20px 10px;
}

.get-quotes-wrap .card-wrap {
    grid-template-columns: repeat(auto-fill, minmax(259px, 1fr)) !important;
    padding: 30px !important;
}

.custom-checkbox .checkbox-wrap {
    padding: 4px 4px !important;
}

