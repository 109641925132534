.gender-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .gender-checkbox-group .checkbox-options {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  
  .gender-checkbox-group label {
    font-weight: bold;
    margin-bottom: 4px;
  }
  