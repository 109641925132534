.rider-table-container {
  max-width: 100%;
  overflow-x: auto;
  padding: 10px;
}

.rider-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-family: inherit;
}

.rider-table th,
.rider-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}


.rider-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.rider-table tr:hover {
  background-color: #f1f1f1;
}

.rider-table input[type="checkbox"] {
  cursor: pointer;
}

.rider-table td {
  vertical-align: middle;
}

.rider-table td:last-child {
  text-align: right;
}

/* Optional: Responsive Design */
@media (max-width: 768px) {

  .rider-table th,
  .rider-table td {
    padding: 8px;
  }

  .rider-table {
    font-size: 14px;
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
  position: relative;
}

[type="radio"]:not(:checked)+label::before {
  left: -22px !important;

}


[type="radio"]:not(:checked)+label {
  padding-left: 0px !important;
}

.get-quotes-wrap .card-wrap {
  padding-left: 30px !important;
}

/* Default styles for desktop: Two riders per row */
.rider-row {
  display: flex;
  justify-content: space-between;
}
 
.rider-cell {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
}
 
.rider-cell.empty-cell {
  visibility: hidden;
}
 
/* Mobile styles: One rider per row */
@media (max-width: 768px) {
  .rider-row {
    flex-direction: column;
  }
 
  .rider-cell {
    width: 100%;
  }
}