.upload-btn input[type="file"] {
    display: none;
}

.upload-btn label {
    display: inline-block;
    padding: 20px 20px;
    border: 2px dashed #ccc;
    border-radius: 0px;
    cursor: pointer;
    background-color: #f8f8f8;
    font-size: 12px;
    color: #666666;
    width: 100%;
}

.upload-btn label:hover {
    background-color: #eaeaea;
}

/* Style the button and hide the file input */
.custom-upload-btn input[type="file"] {
    display: none;
  }
  
  .custom-upload-btn label {
    display: inline-block;
    padding: 20px 20px;
    border: 2px dashed #ccc;
    border-radius: 0px;
    cursor: pointer;
    background-color: #f8f8f8;
    font-size: 12px;
    text-align: center;
    color: #666666;
    width: 100%;

  }
  
  .custom-upload-btn label:hover {
    background-color: #eaeaea;
  }
  
  .text-hidden
  {
    width: 250px;
  max-width: 100%;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  }

  .upload-btn p {
    width: 250px;
    max-width: 100%;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
  }