.rider-selection-wrap * .amount-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-grey {
  text-align: left;
}

.text-right {
  text-align: right;
}

.riders-container {
  display: flex;
  justify-content: space-between;
}

.riders-container,
.add-ons-container {
  margin-top: 10px;
}

.add-ons-items {
  display: flex;
  justify-content: space-between;
  /* Ensure name and amount are on opposite sides */
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  /* Optional: Add separation between items */
  // gap: 97px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
  font-weight: bold;
}

.amount-item.border-box {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 15px;
}

.amount-item.total-item {
  // font-size: 1.2rem;
  font-weight: bold;
}

.text-success {
  cursor: pointer;
}

.text-grey {
  color: #888;
}

.amount-item {
  margin-bottom: 10px;
}

.total-info {
  padding-top: 15px;
  border-top: 1px solid #ddd;
}