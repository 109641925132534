.pagination>li>a {
    background-color: #f7d0d6;
    border-color: #f7d0d6;
    color: #000000;
}

/* (2) Change disabled buttons*/
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
    background-color: #f7d0d6;
    border-color: #f7d0d6;
    color: #000000;
}

/* (3) Change active or hover button color*/
.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>.active>a:focus,
.pagination>li>a:hover,
.pagination>li>a:active,
.pagination>li>a:focus,
.pagination>.active {
    background-color: #f4909f !important;
    border-color: #f7d0d6;
    color: #000000;
}

.pagination>.Item {
    background-color: #f4909f !important;
    border-color: #f7d0d6;
    color: #000000;
}
/* .pagination>active {
    background-color: #f4909f;
    border-color: #f7d0d6;
    color: #000000;
}  */
.active>.page-link, .page-link.active {
    z-index: 3;
    background-color: #ec2d82 !important;
    border-color: #f7d0d6;
    color: #000000;
}
