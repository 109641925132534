.pincode-wrap {
  gap: 8px;
  .input-wrap {
    width: 100%;
  }
  .form-floating {
    width: 100%;
    .floating-input {
      min-height: 48px;
      height: 48px;
      width: 100%;
    }
  }
  .location-icon {
    width: 24px;
    height: 24px;
    margin-top: 17px;
  }
}

.custom-checkbox {
  .checkbox-wrap {
    background: #fff;
    border-radius: 8px;
    border: 2px solid #ec2d82;
    margin-bottom: 10px;
    padding: 8px 5px;
  }
}
